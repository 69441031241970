export const GETTERS = {
  LOADING: 'loading',
  APP_INITIALIZED: 'initialized',
  CAN_ACCESS_APPLICATION_ACCOUNTS: 'canAccessApplicationAccounts',
  USER_ORGANISATION_IS_MASTER: 'userOrganisationIsMaster',
  USER_DISPLAY_NAME: 'userDisplayName',
  USER_ORGANISATIONS_NAMES: 'userOrganisationsNames',
  USER_CAN_MARK_PAYMENT_AS_EXECUTED: 'userCanMarkPaymentAsExecuted',
  USER_CAN_MASS_DELETE_SETTLEMENTS: 'userCanMassDeleteSettlements',
  USER_CAN_SEND_SETTLEMENTS_EMAILS_TO_DRIVERS: 'userCanSendSettlementsEmailsToDrivers',
  USER_HAVE_ACCESS_TO_AUDIT_AREA: 'userHaveAccessToAuditArea',
  USER_CAN_DELETE_DRIVERS: 'userCanDeleteDrivers',
  USER_CAN_EXPORT_SETTLEMENTS: 'userCanExportSettlements',
  USER_CAN_IMPORT_SETTLEMENTS: 'userCanImportSettlements',
  USER_CAN_MODIFY_PETROL_GROSS: 'userCanModifyPetrolGross',
  USER_CAN_MODIFY_RAVAPI_BONUS: 'userCanModifyRavapiBonus',
  USER_CAN_MODIFY_SETTLEMENT_APPLICATION: 'userCanModifySettlementApplication',
  USER_CAN_APPROVE_SETTLEMENTS: 'userCanApproveSettlements',
  USER_CAN_MOVE_SETTLEMENTS_BACK_TO_WAITING: 'userCanMoveSettleentsBackToWaiting',
  USER_CAN_DELETE_PAYOFF: 'userCanDeletePayoff',
  USER_CAN_CANCEL_PAYOFF: 'userCanCancelPayoff',
  USER_CAN_EXPORT_REQUEST_FOR_PAYMENT: 'canExportRequestForPayment',
  USER_HAVE_ACCESS_TO_REPORT_AREA: 'userHaveAccessToReportArea',
  USER_STATISTIC_BUNDLE: 'userGetStatisticBundleId',
  USER_ROLE: 'userRole',
  USER_ID: 'userId',
  ARTICLE_GROUPS: 'articleGroups',
  CONFIRM_IS_OPEN: 'confirmIsOpen',
  VEHICLE_LOG_VISITED: 'vehicleLogVisited',
  USER_CAN_MASS_SET_VEHICLES_COMMISSION: 'canMassSetVehiclesCommission',
  USER_CAN_APPROVE_COLLIDING_SETTLEMENTS: 'canApproveCollidingSettlements',
  USER_CAN_MASS_ADJUST_SETTLEMENT_COMMISSIONS: 'canMassAdjustSettlementCommissions',
  USER_CAN_HOLDOFF_SETTLEMENT_PAYOFF:  'canHoldOffSettlementPayoff',
  USER_CAN_EXPORT_EQUIPMENT_RETURN_REQUEST: 'canExportEquipmentReturnRequest',
  USER_CAN_EXPORT_PROOF_OF_INCOME: 'userCanExportProofOfIncome',
  USER_CAN_REMOVE_DRIVER_CONTACT_FORM: 'userCanRemoveDriverContactForm',
  USER_CAN_EXPORT_PAYMENTS_SUMMARY_FOR_TAX: 'userCanExportPaymentsSummaryForTax',
  USER_CAN_CHANGE_STATE_EXCLUDE_FROM_EXPORT_SETTLEMENT: 'userCanChangeStateExcludeFromExportSettlement',
  USER_CAN_CREATE_INVOICE: 'userCanCreateInvoice',
  USER_CAN_MANUAL_EDIT_DRIVER_SUBJECT: 'userCanManualEditDriverSubject',
  USER_CAN_CHANGE_VEHICLE_IS_INCLUDED_IN_DAYOFF: 'userCanChangeVehicleIsIncludedInDayOff',
  USER_CAN_CHANGE_VEHICLE_COMMISSIONS: 'userCanChangeVehicleCommissions',
  USER_CAN_CHANGE_VEHICLE_DISCOUNTED_COMMISSIONS: 'canChangeVehicleDiscountedCommissions',
  USER_IS_EXCLUDED_FROM_VEHICLE_COMMISSIONS_REMINDER: 'isExcludedFromVehicleCommissionsReminder',
  USER_CAN_MANAGE_VEHICLE_DOCUMENTS: 'canManageVehicleDocuments',
  USER_CAN_IMPORT_PAYOFFS: 'userCanImportPayoffs',
  USER_CAN_ASSIGN_DIGITAL_PROMISSORY_NOTE_IN_RECEIVING_VEHICLE: 'userCanAssignDigitalPromissoryNoteInReceivingVehicle',
  USER_HAVE_ACCESS_TO_DIGITAL_DOCUMENTS_MANAGE_AREA: 'userHaveAccessToDigitalDocumentsManageArea',
  USER_CAN_CONFIRM_DRIVER_DOCUMENTS_WITHOUT_UPLOADING_FILE: 'canConfirmDriverDocumentsWithoutUploadingFile',
  USER_CAN_MANAGE_BONUS_CAMPAIGNS: 'userCanManageBonusCampaigns',
  USER_CAN_REMOVE_BLOCKING_WITHDRAW_DRIVER_STATUS_TAGS: 'canRemoveBlockingWithdrawDriverStatusTags',
  USER_CAN_SKIP_MISSING_VEHICLE_RENT_DIGITAL_DOCUMENTS_POPUP_REMINDER : 'canSkipMissingVehicleRentDigitalDocumentsPopupReminder',
  USER_CAN_VIEW_MISSING_DRIVERS_DIGITAL_DOCUMENTS: 'userCanViewMissingDriversDigitalDocuments',
  USER_CAN_CHANGE_STUDENT_VALIDATION_STATUS: 'userCanChangeStudentValidationStatus',
};

export const ACTIONS = {
  GET_USER_INFO: 'getUserInfo',
  GET_ARTICLE_GROUPS: 'getArticleGroups',
};

export const MUTATIONS = {
  SET_INITIALIZED: 'setInitialized',
  SET_LOADING: 'setLoading',
  SHOW_SNACKBAR_MESSAGE: 'showSnackbarMessage',
  SHOW_CONFIRM: 'showConfirm',
  FINISH_CONFIRM: 'finishConfirm',
  SET_USER_INFO: 'setUserInfo',
  SET_ARTICLE_GROUPS: 'setArticleGroups',
  VISIT_VEHICLE_LOG: 'visitVehicleLog',
  SHOW_ONCE_DIALOG: 'showOnceDialog',
  HIDE_ONCE_DIALOG: 'hideOnceDialog',
};
