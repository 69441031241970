import Vue from 'vue';
import { ApiService } from '@/services/api.service';
import VuePageTransition from 'vue-page-transition';
import VueMask from 'v-mask';
import VueCookies from 'vue-cookies';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import Loading from './plugins/loading';
import Notifier from './plugins/notifier';
import Confirm from './plugins/confirm';
import store from './store';
import LfPlugin from '@/plugins/lf.plugin';
import i18n from '@/i18n/config';
import ValidatorsPlugin from '@/plugins/validators.plugin';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import {
  ArcElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {VPerfectSignature} from 'v-perfect-signature';
import pdf from 'vue-pdf';
import '@/assets/styles/main.scss';
import OnceDialog from '@/plugins/once-dialog';

Vue.config.productionTip = false;

Vue.use(VuePageTransition);
Vue.use(Loading);
Vue.use(Notifier);
Vue.use(Confirm);
Vue.use(OnceDialog);
Vue.use(VueMask);
Vue.use(VueCookies);
Vue.use(LfPlugin);
Vue.use(ValidatorsPlugin);
Vue.use(VPerfectSignature);
Vue.use(pdf);

ChartJS.register(Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, ArcElement, PointElement, ChartDataLabels);

ApiService.init();

// Sentry.init({
//   Vue,
//   dsn: 'https://76e847873f73492bb13a8668fd0b3c43@o4504286306172928.ingest.sentry.io/4504288082853888',
//   integrations: [
//     new BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       tracePropagationTargets: ['test-portal.ravapi.eu', 'portal.ravapi.eu', /^\//],
//     }),
//   ],
//   tracesSampleRate: 1.0,
// });

new Vue({
  router,
  vuetify,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
