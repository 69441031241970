import {datePattern, emailPattern, dateRangePattern, nipPattern} from '@/common/Regex.common';
import moment from 'moment';
import _ from 'lodash';
import i18n from '@/i18n/config';
import { LF } from '@/i18n/LF';
import { IsIntegerValue } from '@/validators/IsIntegerValue.validator';

export default class Validators {
  isEmpty(v: any) {
    if (_.isString(v) && _.isEmpty(v)) {
      return true;
    }

    return _.isNil(v);
  }

  required(v: any) {
    if (_.isString(v) && _.isEmpty(v)) {
      return i18n.t(LF.ThisFieldIsRequired);
    }

    return !_.isNil(v) || i18n.t(LF.ThisFieldIsRequired);
  }

  requiredBoolean(v: any) {
    if (_.isNil(v)) return i18n.t(LF.ThisFieldIsRequired);

    return (typeof v === 'boolean' && v) || i18n.t(LF.ThisFieldIsRequired);
  }

  requiredNumber(defaultNumber: number = 0) {
    return function validate(v: any) {
      return (!_.isNil(v) && v !== defaultNumber) || i18n.t(LF.ThisFieldIsRequired);
    }
  }

  requiredCollection(v: any) {
    return !_.isEmpty(v) || i18n.t(LF.ThisFieldIsRequired);
  }

  email(v: string) {
    return emailPattern.test(v.toLowerCase()) || i18n.t(LF.EmailAddressHasWrongFormat);
  }

  minLength(length: number) {
    return function minLengthValidate(v: string) {
      return (!!v && v.length >= length) || i18n.t(LF.MinTextLength, [length]);
    };
  }

  maxLength(length: number) {
    return function maxLengthValidate(v: string) {
      return !v || v.length <= length || i18n.t(LF.MaxTextLength, [length]);
    };
  }

  dateFormat = function dateFormatValidate(v: string) {
    return !v || datePattern.test(v) || i18n.t(LF.DateFormatMustBeInShortDateFormat);
  };

  dateRangeFormat = function dateRangeFormatValidate(v: string) {
    return !v || dateRangePattern.test(v) || i18n.t(LF.DoubleDatesFormatMustBeInShortDateFormat);
  };

  dateNumbersCorrect = function dateNumbersCorrectValidate(v: string) {
    return !v || moment(v).isValid() || i18n.t(LF.DateIsWrong);
  };

  greaterOrEqualTo(e: number) {
    const { isEmpty } = this;
    return function maxLengthValidate(v: number) {
      return isEmpty(v) || v >= e || i18n.t(LF.ValueCannotBeLowerThan, [e]);
    };
  }

  greaterThan(e: number) {
    const { isEmpty } = this;
    return function maxLengthValidate(v: number) {
      return isEmpty(v) || v > e || i18n.t(LF.ValueMustBeGreaterThan, [e]);
    };
  }

  lessThanOrEqualTo(e: number) {
    const { isEmpty } = this;
    return function minLengthValidate(v: number) {
      return isEmpty(v) || v <= e || `Wartość musi być równa lub mniejsza od ${e}`;
    };
  }

  isNip(e: string | null) {
    return !e || nipPattern.test(e) || i18n.t(LF.InvalidNipFormat);
  }

  maxDecimalPlaces(e: number) {
    const { isEmpty } = this;

    const rawPattern = `^\\d*[\\.,]{1}\\d{${e + 1},}$`;

    const decimalPlacesPattern = new RegExp(rawPattern, 's');

    return function validate(v: number) {
      return isEmpty(v) || !decimalPlacesPattern.test(v.toString(10)) || `Wartość może mieć maksymalnie do ${e} miejsc po przecinku`;
    }
  }

  isValid24HourTimeFormat(v: string) {
    if (!v) return true;
    return /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || 'Nieprawidłowy format czasu'
  }

  isAgeLessThanOrEqualTo(e: number) {
    return function validate(v: string): string | true {
      if (!v) return true;

      const momentDate = moment(v);
      if (!momentDate.isValid()) return 'Nieprawidłowy format daty';

      const momentNow = moment();

      return momentNow.diff(momentDate, 'years') <= e || `Wiek musi być mniejszy lub równy ${e}`;
    }
  }

  isAgeLessThan(e: number) {
    return function validate(v: string): string | true {
      if (!v) return true;

      const momentDate = moment(v);
      if (!momentDate.isValid()) return 'Nieprawidłowy format daty';

      const momentNow = moment();

      return momentNow.diff(momentDate, 'years') < e || `Wiek musi być mniejszy od ${e}`;
    }
  }

  isIntegerValue = IsIntegerValue;
}
