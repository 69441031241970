const ACCESS_TOKEN_KEY = 'access_token';
const REFRESH_TOKEN_KEY = 'refresh_token';
const TOKEN_EXPIRATION_KEY = 'token_expiration';
const IS_SIGNING_DEVICE = 'is_signing_device';

export namespace TokenService {
  export function getToken() {
    return localStorage.getItem(ACCESS_TOKEN_KEY);
  }

  export function getIsSigningDevice() {
    return localStorage.getItem(IS_SIGNING_DEVICE) === 'true';
  }

  export function getRefreshToken(): { refreshToken: string | null, isSigningDevice: boolean } {
    const isSigningDevice = localStorage.getItem(IS_SIGNING_DEVICE) === 'true';
    return { refreshToken: localStorage.getItem(REFRESH_TOKEN_KEY), isSigningDevice };
  }

  export function saveToken(token: string, refreshToken: string, expirationTimestamp: number, isSigningDevice: boolean = false) {
    localStorage.setItem(ACCESS_TOKEN_KEY, token);
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
    localStorage.setItem(TOKEN_EXPIRATION_KEY, expirationTimestamp.toString());
    localStorage.setItem(IS_SIGNING_DEVICE, isSigningDevice ? 'true' : 'false');
  }

  export function destroyToken() {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    localStorage.removeItem(TOKEN_EXPIRATION_KEY);
    localStorage.removeItem(IS_SIGNING_DEVICE);
  }
}
